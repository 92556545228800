import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from 'constants/constants';

// Function to show success toast
const showSuccessToast = (message = SUCCESS_MESSAGE) => {
  // eslint-disable-next-line react/no-unknown-property
  toast.success(<p _id="success_message">{message}</p>, {
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: styles.success
  });
};

// Function to show error toast
const showErrorToast = (message = ERROR_MESSAGE) => {
  // eslint-disable-next-line react/no-unknown-property
  toast.error(<p _id="error_message">{message}</p>, {
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: styles.error
  });
};

export { showErrorToast, showSuccessToast };
