import { Box } from '@mui/material';
import { Button, POIdRenderer, UrlRenderer, Tooltip } from 'components/common';
import styles from './styles';

const formatString = (input) => {
  return input
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9\s]/g, '') // Remove special characters
    .trim() // Remove leading/trailing whitespace
    .replace(/\s+/g, '_'); // Replace spaces with underscores
};

const ReadOnlyList = ({
  heading = '',
  fieldsData = [],
  showButton = false,
  buttonLabel,
  buttonAction,
  disabledAction,
  buttonStyles,
  wrapperCustomStyles
}) => {
  return (
    <Box sx={{ ...styles.wrapper, ...wrapperCustomStyles }}>
      <Box sx={styles.heading}>{heading}</Box>
      <Box sx={styles.cardWrapper}>
        {fieldsData.map(({ label, value }, index) => (
          <Box sx={styles.dataWrapper} key={index}>
            <Box sx={styles.dataHeading}>{label}</Box>
            <Box>
              {value?.type === 'link' ? (
                POIdRenderer(value?.value, label)
              ) : value?.type === 'url' ? (
                UrlRenderer(value?.value)
              ) : value?.type === 'read' ? (
                value?.value?.length > 18 ? (
                  <Tooltip
                    title={value?.value}
                    dataLength={17}
                    _id={formatString(`${heading} ${label}`)}
                  />
                ) : (
                  <Box _id={formatString(`${heading} ${label}`)}>{value?.value}</Box>
                )
              ) : (
                <Box _id={formatString(`${heading} ${label}`)}>{value?.value}</Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
      {showButton && (
        <Button
          label={buttonLabel}
          onClick={buttonAction}
          disabled={disabledAction}
          customStyles={buttonStyles}
        />
      )}
    </Box>
  );
};

export default ReadOnlyList;
