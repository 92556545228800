import { Box } from '@mui/material';
import styles from './styles';

const formatString = (input) => {
  return input
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9\s]/g, '') // Remove special characters
    .trim() // Remove leading/trailing whitespace
    .replace(/\s+/g, '_'); // Replace spaces with underscores
};

const DescriptionBar = ({
  label,
  value,
  renderOptions,
  children,
  customStylesMulti,
  customStyles
}) => {
  return (
    <>
      {renderOptions ? (
        <Box sx={{ ...styles.multiWrapper, ...customStylesMulti }}>
          {renderOptions?.map(({ label, value }, index) => {
            return (
              <Box key={index} sx={styles.optionWrapper}>
                <Box sx={styles.label}>{label}</Box>
                <Box sx={styles.value}>{value}</Box>
              </Box>
            );
          })}
          {children && <Box>{children}</Box>}
        </Box>
      ) : (
        <Box sx={{ ...styles.wrapper, ...customStyles }}>
          <Box sx={styles.optionWrapper}>
            <Box sx={styles.label}>{label}</Box>
            <Box sx={styles.singleValue} _id={formatString(label)}>
              {value}
            </Box>
          </Box>
          {children && <Box>{children}</Box>}
        </Box>
      )}
    </>
  );
};

export default DescriptionBar;
