import { Box } from '@mui/material';
import SimpleButton from '../FormComponents/Button/SimpleButton';
import styles from './styles';

const convertToKebabCase = (str) => {
  return str.toLowerCase().replace(/\s+/g, '-');
};

const MainContent = ({
  heading,
  description,
  children,
  navigateLabel,
  navigateClick,
  onDownload,
  navigateDisable,
  downloadDisable = true
}) => {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.headerWrapper}>
        <Box sx={styles.headingContainer}>
          <Box sx={styles.heading} _id="page_heading">
            {heading}
          </Box>
          <Box sx={styles.description}>{description}</Box>
        </Box>
        <Box sx={styles.buttonWrapper}>
          {navigateLabel && (
            <SimpleButton
              _id={`${convertToKebabCase(heading)}-secondary-btn`}
              disabled={navigateDisable}
              label={navigateLabel}
              onClick={navigateClick}
              customStyles={styles.navigateButton}
            />
          )}
          {onDownload && (
            <SimpleButton
              _id={`${convertToKebabCase(heading)}-download-btn`}
              disabled={downloadDisable}
              label="Download"
              onClick={onDownload}
              customStyles={styles.downloadButton}
            />
          )}
        </Box>
      </Box>
      <Box sx={styles.childrenWrapper}>{children}</Box>
    </Box>
  );
};

export default MainContent;
