import { Button } from '@mui/material';
import styles from './styles';

const formatString = (input) => {
  return input
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9\s]/g, '') // Remove special characters
    .trim() // Remove leading/trailing whitespace
    .replace(/\s+/g, '_'); // Replace spaces with underscores
};

const LinkButton = ({ label, variant = 'outlined', customStyles, disabled, ...rest }) => {
  return (
    <Button
      _id={formatString(`${label} btn`)}
      sx={{ ...styles.wrapper, ...customStyles }}
      variant={variant}
      disabled={disabled}
      {...rest}>
      {label}
    </Button>
  );
};

export default LinkButton;
