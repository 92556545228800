import { Box } from '@mui/material';
import React from 'react';
import { stringTrimmer } from '../../../../utils';
import Tooltip from '../../Tooltip';
import styles from '../styles';

const ProductInfoRenderer = ({
  productDescription,
  skuCode,
  brandSkuCode,
  asinCode,
  fsnCode,
  portalSkuCode,
  dataLength = 3
}) => {
  return (
    <Tooltip
      title={
        <Box>
          <Box _id="product_description">
            Product Description : {productDescription ? productDescription : ''}
          </Box>
          <Box _id="sku_code">SKU Code : {skuCode ? skuCode : ''}</Box>
          <Box _id="brand_sku_code">Brand SKU Code : {brandSkuCode ? brandSkuCode : ''}</Box>
          <Box _id="portal_sku_code">Portal SKU Code : {portalSkuCode ? portalSkuCode : ''}</Box>
          <Box _id="asin_code">ASIN Code : {asinCode ? asinCode : ''}</Box>
          <Box _id="fsn_code">FSN Code : {fsnCode ? fsnCode : ''}</Box>
        </Box>
      }>
      <Box>
        <Box>
          Product Description :{' '}
          {stringTrimmer(productDescription ? productDescription : '', dataLength)}
        </Box>
        <Box sx={styles.productInfo}>
          SKU Code : {stringTrimmer(skuCode ? skuCode : '', dataLength)}
        </Box>
        <Box sx={styles.productInfo}>
          Brand SKU Code : {stringTrimmer(brandSkuCode ? brandSkuCode : '', dataLength)}
        </Box>
        <Box sx={styles.productInfo}>
          Portal SKU Code : {stringTrimmer(portalSkuCode ? portalSkuCode : '', dataLength)}
        </Box>
        <Box sx={styles.productInfo}>
          ASIN Code : {stringTrimmer(asinCode ? asinCode : '', dataLength)}
        </Box>
        <Box sx={styles.productInfo}>
          FSN Code : {stringTrimmer(fsnCode ? fsnCode : '', dataLength)}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default ProductInfoRenderer;
